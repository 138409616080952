import "./index.scss"
import React, { useState, useEffect } from "react"
import { createGroups, getGroups } from "../../services/group"
import { useForm } from "react-form"
import Button from "../../components/common/button/button"
import TextField from "../../components/common/form/text-field/text-field"
import TextArea from "../../components/common/form/text-area/text-area"

import { _ } from "core-js/shim"
import { MdError, MdCheck } from "react-icons/md"

const CreateMedia = ({ setCreateModalOpen, updatedGroups, setGroupWord, setAddedGroup }) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [addGroup, setAddGroup] = useState()
  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [groups, setGroups] = useState()

  const onSaveClick = () => {
    setSubmitAttempted(true)
  }

  useEffect(() => {
    getGroups().then(result => {
      setGroups(groups)
    })
  }, [])

  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      console.log("onSubmit", values)
      createGroups(values).then(
        result => {
          console.log("result", result)
          if (result.error) {
            console.log("Error")
            setShowMessage(true)
            setError(result.error)
          } else {
            setAddedGroup(result.group)
            setAddGroup(values)
            setShowMessage(true)
            setSuccess("Group created successfully")
            setCreateModalOpen(false)
            setGroupWord(groups)
            getGroups().then(groups => {
              setGroupWord(groups)
            })

            updatedGroups()
          }
        },
        error => {
          setShowMessage(true)
          setError("Error")
        }
      )
    },

    validate: (values, instance) => {
      setSubmitAttempted(false)
    },
  })

  const cancelCreateMedia = () => {
    setCreateModalOpen(false)
  }

  const getAddError = () => {
    if (showMessage) {
      if (error) {
        return (
          <div className="groupError">
            <MdError /> {error}
          </div>
        )
      } else {
        return (
          <div className="groupSuccess">
            <MdCheck /> {success}
          </div>
        )
      }
    }
  }

  const { Form, meta, pushFieldValue } = formInstance

  return (
    <div>
      <Form>
        <div>
          {getAddError()}
          <TextField
            label="Add Title(Maximum is 50 characters)"
            placeholder="Add Title"
            field="title"
            // pattern="[a-zA-Z]"
            maxLength="50"
            validate={value => {
              if (value) {
                if (value.length > 50) {
                  return "Title must be maximum 50 characters"
                }
              }
              else {
                return !value ? "Please Enter Title !" : false
              }  
            }}
            mandatory
          />
          <TextArea
            label="Add Description(Maximun is 500 characters)"
            placeholder="Add a description"
            field="groupDescription"
            maxLength="500"
            validate={value => {
              return !value ? "Please Enter Description !" : false
            }}
            mandatory
          />
        </div>
        <br />
        <div className="btns">
          <span className="cancel">
            <Button
              id="cancel-group"
              label="Cancel"
              type="submit"
              btnStyle="secondary"
              btnSize="small"
              onClick={cancelCreateMedia}
            />
          </span>
          <span className="done">
            <Button
              id="create-group"
              label="Done"
              type="submit"
              btnStyle="primary"
              btnSize="small"
            />
          </span>
        </div>
      </Form>
    </div>
  )
}
export default CreateMedia
