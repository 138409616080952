import axios from "axios"
import util from "./util"

export const getGroups = async => {
  return new Promise(function(resolve, reject) {
    axios.get("/groups").then(
      response => {
        // console.log("groups queried successfully", response)
        // resolve(util.fixUrls(response.data))
        resolve(response.data)
      },
      error => {
        console.log("getGroups ERROR: groups not found", error)
        reject(error)
      }
    )
  })
}
export const getGroup = async id => {
  return new Promise(function(resolve, reject) {
    axios.get("/groups/" + id).then(
      response => {
        console.log("groups queried successfully", response)
        // resolve(util.fixUrls(response.data))
        resolve(response.data)
      },
      error => {
        console.log("getGroups ERROR: groups not found", error)
        reject(error)
      }
    )
  })
}

export const createGroups = async group => {
  return new Promise(function(resolve, reject) {
    axios.post("/groups", group).then(
      response => {
        console.log("group created successfully", response)
        var updatedGroups = response.data
        updatedGroups.canEdit = true
        updatedGroups.canDelete = true
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("createGroups error:", error)
        reject(error)
      }
    )
  })
}

export const updateGroups = async (id, groups) => {
  return new Promise(function(resolve, reject) {
    axios.put("/updateGroup/" + id, groups).then(
      response => {
        console.log("Groups " + id + " updated successfully", response)
        // response.data is the updated story object
        resolve(util.fixUrls(response.data))
      },
      error => {
        console.log("updateGroups error:", error)
        reject(error)
      }
    )
  })
}

export const deleteGroups = async groups => {
  var id = _.isString(groups) ? groups : groups._id ? groups._id : groups.id
  return new Promise(function(resolve, reject) {
    axios.delete("/groups/" + id).then(
      response => {
        console.log("Groups " + groups._id + " deleted successfully", response)
        // pass back the deleted story?
        resolve(response)
      },
      error => {
        console.log("deleteGroups error:", error)
        reject(error)
      }
    )
  })
}

export const findByTitle = async title => {
  return new Promise(function(resolve, reject) {
    axios.get("/groups/findByTitle/" + title).then(
      response => {
        const group = response.data.group
        if (group) {
          resolve(group)
        } else {
          console.log("findByTitle ERROR: No Title found")
          resolve(null)
        }
      },
      function(error) {
        console.log("findByTerm ERROR: ".error)
        reject(error)
      }
    )
  })
}

export default {
  getGroups,
  createGroups,
  updateGroups,
  getGroup,
  deleteGroups,
  findByTitle,
}
