import React, { useState, useEffect } from "react"
// import MapUtil from "../../../services/map-util"
// import TestMapLayers from "./map-display-layers"
import { timer } from "rxjs"
import styles from "./map-display.module.scss"
import _, { truncate } from "lodash"
import auth from "../../../services/auth"
import { navigate } from "gatsby"
import 'leaflet/dist/leaflet.css'
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css'

const PAN = "panZoom"
const ZOOM_IN = "zoomIn"
const ZOOM_OUT = "zoomOut"

const MAP_ZOOM_SCALES = {
    18: "1:2,500",
    17: "1:5,000",
    16: "1:10,000",
    15: "1:20,000",
    14: "1:40,000",
    13: "1:80,000",
    12: "1:160,000",
    11: "1:320,000",
    10: "1:640,000",
    9: "1:1,280,000",
    8: "1:2,560,000",
    7: "1:5,120,000",
};

const MARKER_ICON_STYLE = {
    iconUrl: "/assets/img/map/map-pin.svg",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [-3, -76],
    tooltipAnchor: [16, -28]
}

const POLYGON_STYLE = {
    color: "#57C768",
    fillColor: "#DCF7DE",
    fillOpacity: 0.4
}

let boxZoomModel = {
    box: null,
    moved: false,
    startPoint: null,
}

let map
let mcg
let activeToolName = PAN
let mappingMarker
let otherMarker
let markerLocation

let streamAltalisAuthHeader = 'Basic c3BvY190ZXN0OjQwMzNiYzU0ZWI'

let modelMap = {
    showMap: true,
    showMapInfo: false,
    loadingMessage: 'Map is Loading',
}

const MapDisplay = ({geo, media, fnMapPosition, resize, setResize, id}) => {
    const [mapPosition, setMapPosition] = useState()

    if (auth.isBrowser()) {
        var {
            GeoJSON,
            icon,
            imageOverlay,
            ImageOverlay,
            LatLng,
            LatLngBounds,
            Layer,
            LeafletEvent,
            LeafletMouseEvent,
            Marker,
            Point,
            Polygon,
            } = require("leaflet")
        var L = require("leaflet")
        require("proj4leaflet")
        require("leaflet-wms-header")
        require("leaflet.nontiledlayer")
        require("leaflet.vectorgrid")
        require("leaflet.markercluster/dist/leaflet.markercluster")
        var AwesomeMarkers = require("leaflet.awesome-markers")

        const [loading, setLoading] = useState(true)
        // START MAP CONFIG
        // This should be loaded in from a json file
        // **********************************************
        const mapConfig = {
        baseMaps: [
            {
            // //LAYERS=ADMIN_BOUNDARY&STYLES=spoc_ref
            url: "https://stream.altalis.com/ref_wms/ows?",
            headers: [
                {
                header: "Authorization",
                value: streamAltalisAuthHeader,
                },
            ],
            options: {
                layers: "ADMIN_BOUNDARY",
                styles: "spoc_ref",
            },
            label: "Admin Boundary",
            type: "WMS",
            visible: false,
            },
            // // LAYERS=Road&STYLES=spoc_road
            {
            url: "https://stream.altalis.com/bfaccess_wms/ows?",
            headers: [
                {
                header: "Authorization",
                value: streamAltalisAuthHeader,
                },
            ],
            options: {
                layers: "Road",
                styles: "spoc_road",
            },
            label: "Road",
            type: "WMS",
            visible: true,
            },
            // // LAYERS=BaseWaterbodyPolygon&STYLES=spoc_water
            {
            url: "https://stream.altalis.com/bfhydro_wms/ows?",
            headers: [
                {
                header: "Authorization",
                value: streamAltalisAuthHeader,
                },
            ],
            options: {
                layers: "BaseWaterbodyPolygon",
                styles: "spoc_water",
            },
            label: "BaseWaterbodyPolygon",
            type: "WMS",
            visible: true,
            },
            // LAYERS=ATSv4_1Polygons_TownshipIndex&STYLES=spoc_twp
            {
            url: "https://stream.altalis.com/bfats_wms/ows?",
            headers: [
                {
                header: "Authorization",
                value: streamAltalisAuthHeader,
                },
            ],
            options: {
                layers: "ATSv4_1Polygons_TownshipIndex",
                styles: "spoc_twp",
            },
            label: "ATSv4_1Polygons_TownshipIndex",
            type: "WMS",
            visible: true,
            },
            {
            url: "https://stream.altalis.com/cadastral_wms/wms?",
            headers: [
                {
                header: "Authorization",
                value: streamAltalisAuthHeader,
                },
            ],
            options: {
                layers: "cadastral_wms",
            },
            label: "Base Data wms",
            type: "WMS",
            visible: true,
            },
        ],
        esriVectorLayer: {
            url:
            "https://silvacom.maps.arcgis.com/home/item.html?id=71355c21aefb438da60a8daf387137eb",
            layerId: "71355c21aefb438da60a8daf387137eb",
            bounds: new LatLngBounds(
            { lat: 51.042257358849795, lng: -114.04623985290529 },
            { lat: 51.033622027407965, lng: -114.07634496688844 }
            ),
            label: "Calgary Vector Data",
            visible: false,
        },
        // initialBounds: new LatLngBounds([-317030,6196722], [-295692,6185001]),
        initialBounds: new LatLngBounds([49.367, -123.926], [54.96, -105.625]),
        sodaLayers: [
            {
            // https://data.edmonton.ca/Geospatial-Boundaries/Land-Parcels-Title-Parcels/jabg-wnye
            url: "https://data.edmonton.ca/resource/jabg-wnye.geojson",
            // url: 'assets/map/Land Parcels_ Title Parcels.geojson',
            type: "FEATURE",
            label: "Edmonton SODA Data",
            visible: true,
            layer: null,
            bounds: new LatLngBounds(
                { lat: 53.831611, lng: -113.523975 },
                { lat: 53.531611, lng: -113.323975 }
            ),
            },
        ],
        // https://stream.altalis.com/ats_wfs/wfs?SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&TYPENAMES=ats_wfs:ATSv4_1Polygons_Township&TYPENAME=ats_wfs:ATSv4_1Polygons_Township&STARTINDEX=0&COUNT=10000&SRSNAME=urn:ogc:def:crs:EPSG::3401&BBOX=31314.34263481153902831,5649529.58511885069310665,58201.59761186771356734,5688397.89708270318806171,urn:ogc:def:crs:EPSG::3401
        atsConfig: {
            featureNS: "ats_wfs",
            featurePrefix: "wfs",
            featureType: "ATSv4_1Polygons_Township",
            url: "https://stream.altalis.com/ats_wfs/wfs",
            tileField: "TRM",
            proj4:
            "+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=0 +y_0=0 +ellps=GRS80 +datum=NAD83 +units=m +no_defs", // this ats service returns in 3401 only
        },
        // wkid: 3401,
        projection: "EPSG:3857",
        // proj4: '+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=0 +y_0=0 +ellps=GRS80 +datum=NAD83 +units=m +no_defs',
        origin: [-1015998, 4983997],
        resolutions: [
            128,
            64,
            32,
            16,
            8,
            4,
            2,
            1,
            0.5,
            0.25,
            0.125,
            0.0625,
            0.03125,
            0.015625,
            0.0078125,
            0.00390625,
            0.001953125,
        ],
        }
        // **********************************************
        // END MAP CONFIG
        // This should be loaded in from a json file
        // **********************************************

        let model = {
            loadingMessage: true,
            layerPanelVisible: false,
            imageLayers: [],
            layers: [],
            activeTool: PAN,
            position: [0, 0],
            scale: "N/A",
            projection: mapConfig.projection,
            generatingMap: false,
        }

        const calculate = map => {
            return MAP_ZOOM_SCALES[map.getZoom()];
        }

        const addMappingMarker = (geo) => {

            // map.setView(new L.LatLng(geo.lat, geo.lon), 8);

            setTimeout(() => {
                if (mcg) {
                    map.removeLayer(mcg)
                }

                mcg = L.markerClusterGroup()
                mcg.clearLayers();

                console.log('Zero:', geo)
                if (geo.lat !== '' && geo.lat !== 0) {
                    console.log('Geo')
                    mappingMarker = L.marker(new L.LatLng(geo.lat, geo.lon), {
                        icon: customMarker,
                        draggable: true
                    })
                    }
                else if (markerLocation) {
                    mappingMarker = L.marker(new L.LatLng(markerLocation.lat, markerLocation.lon), {
                        icon: customMarker,
                        draggable: true
                    })
                }
                // else {
                //     mappingMarker = L.marker(new L.LatLng(53.9333, -116.5765), {
                //         icon: customMarker,
                //         draggable: true
                //     })                    
                // } 

                if (mappingMarker) {
                    fnMapPosition(mappingMarker.getLatLng())

                    mappingMarker.bindPopup(`<b>Marker</b><br>${mappingMarker.getLatLng()}`)

                    // mappingMarker.on('click', () => {
                    //   console.log('clicked:', mappingMarker.getLatLng())
                    // })

                    mappingMarker.on('mouseover', function(ev) {
                        ev.target.openPopup()
                    })

                    mappingMarker.on('dragend', function(ev) {
                        mappingMarker.unbindPopup()
                        mappingMarker.bindPopup(`<b>Marker</b><br>${mappingMarker.getLatLng()}`)
                        fnMapPosition(mappingMarker.getLatLng())
                    })

                    mappingMarker.addTo(mcg)
                    
                    // map.locate({setView: true, maxZoom: 16})

                    map.addLayer(mcg);
                }    
            }, 500)
        }  

        const addMarkerClusterGroup = (markers) => {

            setTimeout(() => {
                if (mcg) {
                    map.removeLayer(mcg)
                }

                mcg = L.markerClusterGroup()
                mcg.clearLayers();

                setLoadingMessage('Loading markers')

                _.forEach(markers, (data) => {
                if (data.geo) {
                    const lat = data.geo
                    if (lat.lat) {
                    // console.log('Geo:',lat.lat) 
                    const marker = L.marker(new L.LatLng(lat.lat, lat.lon), {
                        icon: customMarker,
                    })

                    marker.on('click', () => {
                        console.log('clicked')
                        navigate(`/app/detailed-file/${data.type}/${data.id}`, {
                        state: {
                            // path: window.location.pathname,
                        },
                        })
                    })
                    marker.on('mouseover', function(ev) {
                        ev.target.openPopup()
                    })
                    marker.addTo(mcg)
                    marker.bindPopup(`<b>Title:${data.title}</b><br>Location:${data.location}<br>Lat:${lat.lat} Long:${lat.lon}`)
                    }
                }

                })

                map.addLayer(mcg);

                setLoadingMessage(null)
            }, 500)

            // setLoading()

        }

        if (media) {
            addMarkerClusterGroup(media)
        }
        // else {
        //     addMappingMarker(geo)
        // }


        const customMarker = new L.Icon({
            iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
            iconSize: [25, 41],
            iconAnchor: [10, 41],
            popupAnchor: [2, -40]
        })

        // const positionMarker =  L.Icon({
        //     iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
        //     iconSize: [25, 41],
        //     iconAnchor: [10, 41],
        //     popupAnchor: [2, -40]
        // })

        const generateMap = (mapId, geo) => {

            model.generatingMap = true
            setupDefaultStyles()
            map = L.map(mapId, {
                maxZoom: 18,
                minZoom: 5, //13
                zoomControl: false,
            })
            map.fitBounds(mapConfig.initialBounds);

            L.control
                .zoom({
                position: "topright",
                })
                .addTo(map)
            // setMap(map)
            addBaseMap(geo) // code for adding a WMS basemap
            addMapEvents()
            model.generatingMap = false
            return map
        }

        const setMap = map => {
            map = map
        }

        const addMapEvents = () => {
            map.on('locationfound', (locationEvent) => {
                markerLocation = {
                    lat: locationEvent.latitude,
                    lon: locationEvent.longitude
                }
            })

            map.on('loading', function (event) {
                console.log('start loading tiles');
            });

            map.on('layeradd', () => {
                // console.log('Layer added')
            })

            map.on('click', function (event) {

                var latlng = map.mouseEventToLatLng(event.originalEvent);
                console.log(latlng.lat + ', ' + latlng.lng);

                const geo = {
                    lat: latlng.lat,
                    lon: latlng.lng
                }

                if (!markerLocation) {
                    addMappingMarker(geo)
                }    
            })

            map.addEventListener("mousemove", event => {
                console.log('Mouse moved')
                // setLoading(true)
                // let projected = this.crs.project(event.latlng);
                // this.model.position[0] = Math.round(projected.x);
                // // this.model.position[1] = Math.round(projected.y);
                // console.log('Mouse moving:', event.latlng.lat)
                model.position[0] = Math.round(event.latlng.lat * 100000) / 100000
                model.position[1] = Math.round(event.latlng.lng * 100000) / 100000

                // console.log("Map Bounds: ", this.map.getBounds());
                calculateScale()
                // mapInfoUpdated.emit(true)
            })

            map.addEventListener("zoomstart", event => {
                setLoading(true)
                console.log('Zomm has started')
                // refreshSodaData()
                // mapInfoUpdated.emit(true)
            })

            map.addEventListener("zoomend", event => {
                calculateScale()
                console.log('Zomm has finished')
                setLoading(false)
                // refreshSodaData()
                // mapInfoUpdated.emit(true)
            })

            map.addEventListener("moveend", event => {
                setLoading(false)
                // refreshSodaData()
                // mapInfoUpdated.emit(true)
            })

            map.addEventListener("movestart", event => {
                setLoading(true)
                // refreshSodaData()
                // mapInfoUpdated.emit(true)
            })

            map.addEventListener("load", event => {
                calculateScale()
                // refreshSodaData()
                // mapInfoUpdated.emit(true)
            })
        }

        const calculateScale = () => {
            // uses a format 1:xxxxx
            model.scale = calculate(map)

            // https://gis.stackexchange.com/questions/7430/what-ratio-scales-do-google-maps-zoom-levels-correspond-to/7443#7443
            // https://groups.google.com/g/google-maps-js-api-v3/c/hDRO4oHVSeM/m/osOYQYXg2oUJ
            // let metersPerPx = 156543.03392 * Math.cos(this.map.getCenter().lat * Math.PI / 180) / Math.pow(2, this.map.getZoom());
            // this.model.scale = "approx. " + Math.round(metersPerPx / 1000) + "m/pixel";
            }

            // NOTE this gets set and cleared for a few different conditions but never shown. We just show a loading spinner on the map
            const setLoadingMessage = value => {
            model.loadingMessage = value
            // loadingMessageChange.emit(value)
        }

        const addBaseMap = (geo) => {

            _.forEach(mapConfig.baseMaps, baseMap => {
                if (baseMap.type === "WMS") {
                let wmsBaseMap = baseMap
                // common defaults
                let opts = {
                    dpi: "96",
                    map_resolution: "96",
                    format_options: "dpi:96",
                    transparent: "true",
                    version: "1.3.0",
                    format: "image/png",
                    useCanvas: false,
                    // subdomains: ['stream1', 'stream2', 'stream4', 'stream4']
                }
                if (!wmsBaseMap.options) {
                    wmsBaseMap.options = {}
                }
                wmsBaseMap.options = _.extend(opts, wmsBaseMap.options)
                wmsBaseMap.options ? wmsBaseMap.options : {}

                let headers = baseMap.headers ? baseMap.headers : []
                let layer = L.tileLayer.wms(
                    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    {
                        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                        // layers: 'TOPO-OSM-WMS'
                    }
                )

                layer.addTo(map)
                // layer.on("load", () => {
                //   console.log('Layer loaded')
                // })

                layer.on("loading", function() { console.log("Tiles loading") })
                layer.on("load",function() { 
                    console.log("all visible tiles have been loaded")
                     setTimeout(() => { 
                        setLoading(false)
                     }, 500)   
                })

                baseMap.layer = layer
                addLayerToc(baseMap)

                if (geo) {
                    if (geo.lat === '') {
                        map.locate({setView: true, maxZoom: 16})
                    }
                    else {
                        map.setView(new L.LatLng(geo.lat, geo.lon), 16)
                    }
                }    
                }
            })
        }

 
        const setupDefaultStyles = () => {
            Marker.prototype.options.icon = icon(MARKER_ICON_STYLE)
            Polygon.prototype.options = _.extend(
                    Polygon.prototype.options,
                    POLYGON_STYLE
                )
            }

            const addLayerToc = (layerSpec) => {
            // we only show image layers in the plan
            // if (layerSpec.isImageLayer) {
            if (model.layers.indexOf(layerSpec) === -1) {
                // if (replaceIdx) {
                //   this.model.layers.splice(replaceIdx, 0, layerSpec);
                // } else {
                model.layers.push(layerSpec)
                // }
            }

            // layersChange.emit(model.layers)
            // }
        }

        const updateLayer = (layerSpec) => {

            console.log('updateLayer')
            if (layerSpec.visible) {
                console.log('Add layer')
                if (layerSpec.layer && layerSpec.layer.addTo) {
                    layerSpec.layer.addTo(map);
                }
            } else {
                console.log('Remove layer')
                if (layerSpec.layer && layerSpec.layer.removeFrom) {
                    layerSpec.layer.removeFrom(map);
                }
            }

            
            const index = model.layers.indexOf(layerSpec)
            model.layers[index].visible = layerSpec.visible
        }

        const zoomTo = (layerSpec) => {
            if (layerSpec.bounds) {
                map.fitBounds(layerSpec.bounds);
            } else if (layerSpec.layer && layerSpec.layer.getBounds) {
                map.fitBounds(layerSpec.layer.getBounds());
            }
        }

        const toggleTool = toolName => {
            if (activeToolName !== toolName) {
                // turn off the current active tool. Remove listeners
                if (
                activeToolName === ZOOM_IN ||
                activeToolName === ZOOM_OUT
                ) {
                    map.off("mousedown", boxZoomMouseDown, this)
                    map.off("mousemove", boxZoomMouseMove, this)
                    map.off("mouseup", boxZoomMouseUp, this)
                    map.dragging.enable()
                    map.getContainer().style.cursor = ""
                }

                // turn on the new active tool
                activeToolName = toolName
                if (
                toolName === ZOOM_IN ||
                toolName === ZOOM_OUT
                ) {
                    map.getContainer().style.cursor = "crosshair"
                    map.dragging.disable()
                    map.on("mousedown", this.boxZoomMouseDown, this)
                // other mouse events get added later
                } else if (toolName === PAN) {
                // we don't actually have to do anything with this tool... Maybe change the cursor?
                }
            }
        }

        // **********************************************
        // START mouse zoom events for this.map
        // **********************************************
        const boxZoomMouseDown = event => {
            boxZoomModel.moved = false

            map.on("mousemove", boxZoomMouseMove, this)
            map.on("mouseup", boxZoomMouseUp, this)
            boxZoomModel.startPoint = map.mouseEventToLayerPoint(
                event.originalEvent
            )
            // this.map.boxZoom._onMouseDown.call(this.map.boxZoom, { clientX: event.originalEvent.clientX, clientY: event.originalEvent.clientY, which: 1, shiftKey: true });
            }

            const boxZoomMouseMove = event => {
            if (!boxZoomModel.moved) {
                boxZoomModel.box = L.DomUtil.create(
                    "div",
                    "leaflet-zoom-box",
                    map.getPane("overlayPane")
                )
                L.DomUtil.setPosition(boxZoomModel.box, boxZoomModel.startPoint)
            }

            let startPoint = boxZoomModel.startPoint
            let layerPoint = map.mouseEventToLayerPoint(event.originalEvent)
            let offset = layerPoint.subtract(startPoint)
            let newPos = new L.Point(
                Math.min(layerPoint.x, startPoint.x),
                Math.min(layerPoint.y, startPoint.y)
            )

            L.DomUtil.setPosition(boxZoomModel.box, newPos)

            boxZoomModel.moved = true

            // remove 4 pixels to allow for an offset
            let width = Math.max(0, Math.abs(offset.x) - 4)
            let height = Math.max(0, Math.abs(offset.y) - 4)

            boxZoomModel.box.style.width = width + "px"
            boxZoomModel.box.style.height = height + "px"
        }

        const boxZoomMouseUp = event => {
            // these will get re-added on mouse down again
            map.off("mousemove", boxZoomMouseMove, this)
            map.off("mouseup", boxZoomMouseUp, this)

            // fetch the box and convert to a map bbox, before we clear it
            let ul = boxZoomModel.box._leaflet_pos
            let lr = new L.Point(
                boxZoomModel.box._leaflet_pos.x + boxZoomModel.box.offsetWidth,
                boxZoomModel.box._leaflet_pos.y + boxZoomModel.box.offsetHeight
            )
            let nw = map.layerPointToLatLng(ul)
            let se = map.layerPointToLatLng(lr)
            if (nw.equals(se)) {
                return
            }

            let bounds = new L.LatLngBounds(nw, se)
            if (activeToolName === ZOOM_IN) {
                map.fitBounds(bounds)
            } else {
                // Do a zoom out using the box
                let mapBounds = map.getBounds()
                let mapWidth = mapBounds.getEast() - mapBounds.getWest()
                let mapHeight = mapBounds.getNorth() - mapBounds.getSouth()

                let zoomWidth = bounds.getEast() - bounds.getWest()
                let zoomHeight = bounds.getNorth() - bounds.getSouth()

                let avgZoomDiff = (mapHeight / zoomHeight + mapWidth / zoomWidth) / 2
                let zoomLevelDiff = Math.round(Math.log2(avgZoomDiff))
                map.setZoom(this.map.getZoom() - zoomLevelDiff)
                map.panTo(bounds.getCenter())
            }

            map.getPane("overlayPane").removeChild(boxZoomModel.box)
        }

        const onKey = event => {
            console.log('Event:', event)
            if (event.altKey && event.ctrlKey && event.key === "i") {
                modelMap.showMapInfo = !modelMap.showMapInfo
                modelMap.layerPanelVisible = modelMap.showMapInfo
            }
        }

        const onZoom = event => {
            console.log('onZooms', event)
        }


        useEffect(() => {
            map = generateMap(id, geo)

            console.log('Map Created')
            if (!media) {
                addMappingMarker(geo)
            }
        }, [])

        const onResized = count => {
            if (map) {
                map.invalidateSize();
            }
            // attempt to resize a few times to ensure the component has had time to resize
            if (count <= 5) {
                timer(50).subscribe(() => {
                    onResized(count++);
                })
            }

            setResize(false)
        }

        if (resize) {
           onResized(1)
        }

        console.log('Loading:',loading)

        return (
            <div className={styles.wrapper}>
                {loading ? <div>Please wait while map is loading...</div> : ''}
                <div className={styles.map} id={id} onKeyUp={onKey}>
                </div>
             </div>
        )
    }
}

export default MapDisplay
