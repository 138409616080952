import PropTypes from "prop-types"
import React from "react"
import styles from "./thumbnail.module.scss"
import classNames from "classnames"
import Waveform from "../../../media-output/audiowave"
import { MdPhoto, MdPictureAsPdf, MdHeadset,MdVideocam } from "react-icons/md"
import { GiOpenBook } from "react-icons/gi"
import { IoMdDocument } from "react-icons/io"

const Thumbnail = (
  { data, 
    featuredPhotos, 
    showPlaceHolder, 
    standardCard, 
    mediaPlayingId, 
    fnSetMediaPlayingId, 
    mediaPlaying, 
    fnSetMediaPlaying, 
    audioFile }) => {

  const shouldDisplayPlaceholder = ignorePending => {
    let ref = data && data.source_file ? data.source_file : data
    return (
      (ref.thumbnailStatus &&
      ((ref.thumbnailStatus === "PENDING" && !ignorePending) ||
        ref.thumbnailStatus === "EMPTY")) ||
      ref.thumbnailUrl === ''
    )
  }

  var type = data.type ? data.type : data.mediaType
  switch (type) {
    case "Photo":
      // TODO this is always pending on upload even though it seems to be available? "true" can be false if this was working
      if (shouldDisplayPlaceholder(true)) {
        return (
          <div
            className={classNames(
              styles.icons,
              styles.thumbnailIconPhoto,
              "thumbnail-icon-photo"
            )}
          ></div>
        )
      } else {
        if (!standardCard) {
          return <img src={data.thumbnailUrl} alt={data.title} />
        }
        else {
          return (
            <div className={styles.container}>
              <img src={data.thumbnailUrl} alt={data.title} />
              <div className={styles.overlay}>
                <div className={styles.playIcon}>
                  <MdPhoto />
                </div>
              </div>              
            </div>            
          )

        }
      }
    case "Video":
      if (shouldDisplayPlaceholder(true)) {
        return (
          <div
            className={classNames(
              styles.icons,
              styles.thumbnailIconVideo,
              "thumbnail-icon-video"
            )}
          ></div>
        )
      } else {
          if (!standardCard) {
            return <img src={data.thumbnailUrl} alt={data.title} />
          }
          else {            
          return (
            <div className={styles.container}>
              <img src={data.thumbnailUrl} alt={data.title} />
              <div className={styles.overlay}>
                <div className={styles.playIcon}>
                  <MdVideocam />
                </div>
              </div>
            </div>                  
          )
        }  
      }
    case "Audio":
      if (shouldDisplayPlaceholder(false)) {
        return (
          <div
            className={classNames(
              styles.thumbnailIconAudio,
              styles.icons,
              "thumbnail-icon-audio"
            )}
          ></div>
        )
      } else {
        if (!standardCard) {
          return (
            <div
              className={classNames(
                styles.thumbnailIconAudio,
                styles.icons,
                "thumbnail-icon-audio"
              )}
            ></div>
          )
        }
        else {
          return (
            <div className={styles.container}>
              <Waveform 
                data={data} 
                mediaPlayingId={mediaPlayingId} 
                fnSetMediaPlayingId={fnSetMediaPlayingId} 
                mediaPlaying={mediaPlaying} 
                fnSetMediaPlaying={fnSetMediaPlaying}
              />
              <div className={styles.overlay}>
                <div className={styles.playIcon}>
                  <MdHeadset />
                </div>
              </div>
            </div>  
          )
        }  
      }
    case "Document":
      if (shouldDisplayPlaceholder(true)) {
        return (
          <div
            className={classNames(
              styles.icons,
              styles.thumbnailIconPdf,
              "thumbnail-icon-pdf"
            )}
          ></div>
        )
      } else {
        if (!standardCard) {
          return <img src={data.thumbnailUrl} alt={data.title} />
        }
        else {
          return (
            <div className={styles.container}>
              <img src={data.thumbnailUrl} alt={data.title} />
              <div className={styles.overlay}>
                <div className={styles.playIcon}>
                  <IoMdDocument />
                </div>
              </div>
            </div>                  
          )
        }  
      }

    case "Archive":
      return (
        <div
          className={classNames(
            styles.thumbnailIconFile,
            styles.icons,
            "thumbnail-icon-file"
          )}
        ></div>
      )
    case "Story":
      if (shouldDisplayPlaceholder(false)) {
        return (
          <div
            className={classNames(
              styles.icons,
              styles.thumbnailIconStory,
              "thumbnail-icon-story"
            )}
          ></div>
        )
      } else {
        if (!standardCard) {
          return <img src={data.thumbnailUrl} alt={data.title} />
        }
        else {
          return (
            <div className={styles.container}>
              <img src={data.thumbnailUrl} alt={data.title} />
              <div className={styles.overlay}>
                <div className={styles.playIcon}>
                  <GiOpenBook />
                </div>
              </div>
            </div> 
          )
        }  
      }
    default:
      return <div>Unknown content type</div>
  }
}

Thumbnail.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Thumbnail
