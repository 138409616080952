import React, { useEffect, useRef, useState } from "react"

import WaveSurfer from "wavesurfer.js"
import axios from "axios"
import  "./index.scss"
import Button from "../../common/button/button"

const Waveform = ({key, data, webfile, playMode, mediaPlayingId, fnSetMediaPlayingId, mediaPlaying, fnSetMediaPlaying}) => {

  const waveformRef = useRef(null)
  const wavesurfer = useRef(null)
  const [playing, setPlay] = useState(false)
  const [volume, setVolume] = useState(0.5)
  const [duration, setDuration] = useState(null)
  const [timeElapsed, setTimeElapsed] = useState(0)

  let url = ''
  if (webfile) {
    if (data.source_file) {
      url = axios.defaults.baseURL + "download/" + data.source_file.id + "/" + data.filename
    }
    else {
      url = data.webViewUrl.replace('/webView/', '/download/')
    }  
  }
  else {
    url = axios.defaults.baseURL + "download/" + data.fileId + "/" + data.fileName
  }
    
  // create new WaveSurfer instance
  // On component mount and when url changes

  const formWaveSurferOptions = ref => ({
    container: ref,
    backend: 'MediaElement'
    // waveColor: "#000000",
    // progressColor: "CornFlowerBlue",
    // cursorColor: "CornFlowerBlue",
    // barWidth: 3,
    // barHeight: 2,
    // barGap: null
    // barRadius: 3,
    // responsive: true,
    // height: 120,
    // normalize: true,
    // partialRender: true,
  })

  useEffect(() => {
    setPlay(false)
    const options = formWaveSurferOptions(waveformRef.current)
    wavesurfer.current = WaveSurfer.create(options)
    wavesurfer.current.load(url)
    
    wavesurfer.current.on('finish', function () {
      console.log('Finish')
      fnSetMediaPlayingId('')
      fnSetMediaPlaying(false)
    });

    // wavesurfer.current.on("ready", function() {
    //   if (wavesurfer.current) {
    //     wavesurfer.current.setVolume(volume)
    //     setVolume(volume)
    //     setDuration(wavesurfer.current.getDuration().toFixed(2))
    //   }
    // })
    // wavesurfer.current.on("audioprocess", () => {
    //   setTimeElapsed(wavesurfer.current?.getCurrentTime().toFixed(2))
    // })
    // Removes events, elements and disconnects Web Audio nodes.
    // when component unmount
    return () => wavesurfer.current.destroy()
  }, [url])

  const handlePlayPause = () => {
    if (webfile) {
      console.log('PlayPause')
      setPlay(!playing)
      wavesurfer.current.playPause()
    }
    else {
      if (!mediaPlaying) {
        fnSetMediaPlayingId(data.id)
        fnSetMediaPlaying(!playing)
        setPlay(!playing)
        wavesurfer.current.playPause()
      }
      else {
        if (data.id === mediaPlayingId || mediaPlayingId === '') {
          setPlay(!playing)
          fnSetMediaPlaying(!playing)
          wavesurfer.current.playPause()
        }
      }
    }    
   
  }


  const onVolumeChange = e => {
    const { target } = e
    const newVolume = +target.value
    if (newVolume) {
      setVolume(newVolume)
      wavesurfer.current.setVolume(newVolume || 1)
    }
  }

  return (
    <div>
      <div id="waveform" ref={waveformRef} />
      <div className="controls">
        <Button
          id="play-pause"
          type="button"
          btnStyle="primary"
          btnSize="small"
          prependIcon={!playing ? "MdPlayArrow" : "MdPause"}
          onClick={handlePlayPause}
          // style={{width: "30px", height: "30px"}}
        />
        {/* <button onClick={handlePlayPause}></button>                */}
        {/* <input
          type="range"
          id="volume"
          name="volume"
          min="0.01"
          max="1"
          step=".025"
          onChange={onVolumeChange}
          defaultValue={volume}
        /> */}
        {/* <label htmlFor="volume"> Volume</label> <br /> */}
        {/* <p>{timeElapsed} - Duration in seconds</p> */}
      </div>
      {/* <p>{timeElapsed} - Time Elapsed</p> */}
    </div>
  )
}

export default Waveform
