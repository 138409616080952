import React from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import { useField, splitFormProps } from "react-form"
import { MdError, MdDateRange } from "react-icons/md"
import moment from "moment"
import { useState } from "react"
import classNames from "classnames"
import Label from "../label/label"
import ErrorMessage from "../error-message/error-message"

import "react-datepicker/dist/react-datepicker.css"
import "./year-month.scss"

const YearMonth = props => {
  // Let's use splitFormProps to get form-specific props

  const [isFocus, setFocus] = useState(false)
  const [startDate, setStartDate] = useState(new Date());

  const [
    field,
    fieldOptions,
    { label, required, mandatory, description, showDay, errorMessage, dateUnknown, ...rest },
  ] = splitFormProps(props)

  // Use the useField hook with a field and field options
  // to access field state
  const {
    value = null,
    setValue,
    meta: { error, isTouched },
  } = useField(field, fieldOptions)

  const getSelected = () => {
    if (value) {
      if (value.length === 6) {
        return moment(value, "YYYYMM").toDate()
      } else if (value.length === 8) {
        return moment(value, "YYYYMMDD").toDate()
      }
    } else if (dateUnknown) {
      return null
    } else {
      const value = moment(startDate).format("YYYYMMDD")
      setValue(value)
      return startDate
    }
  }

  const onChange = d => {
    var value = null
    if (d) {
      if (showDay) {
        value = moment(d).format("YYYYMMDD")
      } else {
        value = moment(d).format("YYYYMM") + "01"
      }
    }

    setValue(value)
  }

  return (
    <div className="year-month-wrapper">
      <Label label={label} mandatory={mandatory} description={description} />
      <div className={classNames("year-month-wrap", isFocus ? " active" : "", 
                                 "year-month-wrap", !mandatory ? " disabled" : "")}>
        <div className="icon">
          <MdDateRange />
        </div>

        <DatePicker
          showMonthYearPicker={!showDay}
          showYearDropdown={showDay}
          showMonthDropdown={showDay}
          dropdownMode="select"
          id={field}
          selected={getSelected()}
          onChange={onChange}
          dateFormat={showDay ? "MMM/dd/yyyy" : "MM/yyyy"}
          autoComplete="off"
          onFocus={() => setFocus(true)}
          onBlur={() => {
            setFocus(false)
            if (mandatory) {
              setValue(value) // reset the value to trigger validation. Needed for when the field is clicked on but not set
            }  
          }}
          {...rest}
          {...{ required }}
        />

        {isTouched && error ? (
          <div
            className="icon error-icon"
            data-tip={error}
            data-event="click focus"
          >
            <MdError />
          </div>
        ) : (
          ""
        )}
      </div>
      {error && mandatory ? <ErrorMessage errorMessage={error} /> : ""}
    </div>
  )
}

YearMonth.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  mandatory: PropTypes.bool,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
}

export default YearMonth
