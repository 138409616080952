import React from "react"
import styles from "./suggested-keywords.module.scss"

const SuggestedKeywords = ({ words, add }) => {
  return (
    <ul className={styles.wrap}>
      {words.map(k => (
        <li key={k._id} onClick={() => add(k)}>
          {k.term}
        </li>
      ))}
    </ul>
  )
}

export default SuggestedKeywords
