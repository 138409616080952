import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import styles from "./webfile.module.scss"
import Waveform from "../../../media-output/audiowave"
import classNames from "classnames"

const Webfile = ({ item, type }) => {

  const [innerContent, setInnerContent] = useState("")

  const shouldDisplayPlaceholder = ignorePending => {
    let ref = item && item.source_file ? item.source_file : item
    return (
      ref.webViewStatus &&
      ((ref.webViewStatus === "PENDING" && !ignorePending) ||
        ref.webViewStatus === "EMPTY")
    )
  }

  const displayArchive = () => {
    return (
      <div
        className={classNames(
          styles.webfileIconFile,
          styles.icons,
          "webfile-icon-file"
        )}
      ></div>
    )
  }
  const displayAudio = () => {
    if (shouldDisplayPlaceholder()) {
      return (
        // <div
        //   className={classNames(
        //     styles.webfileIconAudio,
        //     styles.icons,
        //     "webfile-icon-audio"
        //   )}
        // ></div>
        <Waveform data={item} webfile={true}/>
      )
    } else {
      return (
        <div className={styles.audioWrapper}>
          <audio controls autoplay>
            <source src={item.webViewUrl} />
            Your browser does not support the audio tag.
          </audio>
        </div>
      )
    }
  }
  const displayDocument = () => {
    
    if (shouldDisplayPlaceholder(true)) {
      return (
        <div
          className={classNames(
            styles.webfileIconPdf,
            styles.icons,
            "webfile-icon-pdf"
          )}
        ></div>
      )
    } else {
      return (
        <img
          id={item.id + "_document"}
          className={styles.photoWrapper}
          src={item.webViewUrl}
        />
      )
    }
  }
  const displayPhoto = () => {
    // TODO this is always pending on upload even though it seems to be available? "true" can be false if this was working
    if (shouldDisplayPlaceholder(true)) {
      return (
        <div
          className={classNames(
            styles.webfileIconPhoto,
            styles.icons,
            "webfile-icon-photo"
          )}
        ></div>
      )
    } else {
      return (
        <img
          id={item.id + "_photo"}
          className={styles.photoWrapper}
          src={item.webViewUrl}
        />
      )
    }
  }
  const displayVideo = () => {
    if (shouldDisplayPlaceholder(true)) {
      return (
        <div
          className={classNames(
            styles.webfileIconVideo,
            styles.icons,
            "webfile-icon-video"
          )}
        ></div>
      )
    } else {
      return <img className={styles.videoWrapper} src={item.webViewUrl} />

      // TODO we could return this instead if we get the icons working and the filetype is mp4
      // <Player
      //   className={styles.wrapper}
      //   src={item.source_file.url}
      //   playsInline
      //   poster={item.webview_file ? item.webview_file.url : ""}
      // ></Player>
    }
  }

  // displayStory probably doesn't get used anywhere...
  const displayStory = () => {
    return <div className={styles.storyWrapper}></div>
  }

  useEffect(() => {
    switch (type) {
      case "Archive":
        setInnerContent(displayArchive())
        break
      case "Audio":
        setInnerContent(displayAudio())
        break
      case "Document":
        setInnerContent(displayDocument())
        break
      case "Photo":
        setInnerContent(displayPhoto())
        break
      case "Video":
        setInnerContent(displayVideo())
        break
      case "Story":
        setInnerContent(displayStory())
        break
      default:
        setInnerContent(<div>Unknown File Type</div>)
    }
  }, [type, item])

  return <div className={styles.wrapper}>{innerContent}</div>
}

Webfile.defaultProps = {}

Webfile.propTypes = {
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

export default Webfile
