import _ from "lodash"
import React, { useState, useEffect } from "react"
import { useForm } from "react-form"
import Button from "../common/button/button"
import MapDisplay from "../common/map-display/map-display"
import TextField from "../common/form/text-field/text-field"
import auth from "../../services/auth"
import locationService from "../../services/location"
import { MdError, MdCheck } from "react-icons/md"
import styles from "./media-mapping.module.scss"

const MediaMapping = ({ setLocation, setMapModal, location, geo, onMapClose, create }) => {
  const [submitAttempted, setSubmitAttempted] = useState()
  const [keywords, setKeywords] = useState([])
  const [showMessage, setShowMessage] = useState(false)
  const [error, setError] = useState()
  const [mapPosition, setMapPosition] = useState()
  const [selectedData, setSelectedData] = useState({
    id: null,
    name: "",
  })
  

  const handleOnChange = (dataKey, val) => {
    setSelectedData({
      ...selectedData,
      [dataKey]: val,
    })
  }

  const formInstance = useForm({
    debugForm: false,
    onSubmit: values => {
      
      console.log('Geo Points:', mapPosition)

      const name = {
        name: values.name
      }

      locationService.createLocation(name).then(
        result => {
          console.log('Location created:', name)
        },
        error => {
          console.log('Error creating location:', name)
        }
      )

      const geo = mapPosition

      const location = {
        geo: {
          lat: geo.lat,
          lon: geo.lng
        },
        name: values.name
      }

      setLocation(location)
      setMapModal(false)
    },

    validate: (values, instance) => {
      setSubmitAttempted(false)

      if (!values.term) {
        return true //  "name is required"
      }
    },
  })

  const onCancelClick = () => {
    onMapClose()
  }

  const onMappingClick = () => {
    console.log('Done clicked')
  }

  const { Form, meta, pushFieldValue } = formInstance

  return (
    <>
      <div className={styles.wrapper}>
        <div>Please drag the marker to the location(Click map if no marker)</div>
        <br />
        <div classNME={styles.map}>
          {auth.isBrowser() ? <MapDisplay  geo={geo} fnMapPosition={setMapPosition} resize={false} id={'media-mapping'}/> : ''}
        </div>
        <br/>
        <Form>
          <div>
            <div>
              <TextField
                // style={{ width: "450px" }}
                field="name"
                defaultValue={location}
                label="Location"
                // defaultValue={value}
                onChange={e => handleOnChange("name", e.target.value)}
                validate={value => {
                  return !value ? "Please Enter a Valid Term!" : false
                }}
                mandatory
              />
            </div>
            <div className={styles.actionGroup}>
              <div className={styles.actions}>
                <div className={styles.subButton}>
                  <Button
                    id="cancel-map"
                    label="Cancel"
                    btnStyle="secondary"
                    btnSize="medium"
                    fullWidth
                    prependIcon="MdCancel"
                    onClick={onCancelClick}
                  />
                  <Button
                    id="add-position"
                    type="submit"
                    label="Done"
                    btnStyle="primary"
                    btnSize="medium"
                    fullWidth
                    // onClick={onMappingClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  )
}

export default MediaMapping
