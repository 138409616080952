import { useState } from "react"
import axios from "axios"

const usePeopleTags = () => {
  const [peopleTags, setPeople] = useState([])
  const updatePeopleTags = async newPeople => {
    const safeNewPeople = newPeople || []
    const sortedNew = _.sortBy(
      [..._.uniqBy([...safeNewPeople], "value")],
      ["value"]
    )
    // handles search field change
    if (
      (peopleTags || []).length !== sortedNew.length ||
      sortedNew.some(
        v => (people || []).find(v2 => v.value === v2.value) === undefined
      )
    ) {

      const newPeopleObjs = await Promise.all(
        sortedNew.map(k => {
          if (k.isNewKey) {
            console.log('Adding:', k.value)
            return axios.post("people/", {name: k.value})
          }
          else {
            return axios.get("people/findByName/" + k.source.name)
          }  
        })
      )


      setPeople(sortedNew)
    }
  }
  return [peopleTags, updatePeopleTags]
}

export default usePeopleTags