import { DataSearch } from "@appbaseio/reactivesearch"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useField, splitFormProps } from "react-form"
import * as ReactIcon from "react-icons/md"
import { MdCancel } from "react-icons/md"
import SearchWrapper from "../../../common/search-wrapper/search-wrapper"
import "./autocomplete.scss"
import Label from "../../../common/form/label/label"
import ErrorMessage from "../../../common/form/error-message/error-message"

function Autocomplete(props) {
  const [
    field,
    fieldOptions,
    {
      index,
      label,
      mandatory,
      description,
      icon,
      dataField,
      placeholder,
      clearOnSelect,
      allowMultiple,
      onChange,
      showClearButton,
      strictSelection,
    },
  ] = splitFormProps(props)

  // Use the useField hook with a field and field options
  // to access field state
  const fieldInstance = useField(field, fieldOptions)
  const [curText, setCurText] = useState("")
  const {
    meta: { error, isTouched },
  } = fieldInstance
  useEffect(() => {
    if (clearOnSelect) {
      setCurText("")
    } else {
      // updates the value when refreshed from init. (E.g. search screen)
      let value = fieldInstance.value
      if (value && _.isString(value)) {
        setCurText(value)
      } else {
        setCurText(value && value.value ? value.value : "")
      }
    }
  }, [fieldInstance.value])

  const clearValue = () => {
    setCurText("")
    fieldInstance.setValue({ value: null })
    onChange({ value: null })
  }

  const getIsStrict = () => {
    // defaults to true. Return false only if explicitly stated.
    return strictSelection === undefined || strictSelection === null
      ? true
      : strictSelection
  }

  const getClearButton = () => {
    if (
      showClearButton &&
      !allowMultiple &&
      fieldInstance.value &&
      fieldInstance.value.value
    ) {
      return (
        <MdCancel
          className="close-icon"
          onClick={() => {
            clearValue()
          }}
        />
      )
    }
  }

  return (
    <SearchWrapper index={index}>
      <Label label={label} mandatory={mandatory} description={description} />
      <div className="search-from-wrap">
        <DataSearch
          className="search-form-group"
          innerClass={{
            title: "search-title",
            input: "search-input",
          }}
          dataField={dataField}
          placeholder={placeholder}
          autosuggest={true}
          componentId={label}
          value={curText}
          onChange={v => {
            if (getIsStrict()) {
              if (fieldInstance.value && fieldInstance.value.value === v) {
                setCurText("")
              } else {
                setCurText(v ? v : "")
              }
            } else {
              setCurText(v ? v : "")
            }
          }}
          onBlur={() => {
            if (getIsStrict()) {
              if (!clearOnSelect) {
                let newValue = ""
                if (fieldInstance.value) {
                  if (_.isObject(fieldInstance.value)) {
                    newValue = fieldInstance.value.value
                  } else {
                    // this handles the rare case that the loaded value doesn't match with any of the select options
                    newValue = fieldInstance.value
                  }
                }
                if (newValue !== curText) {
                  setCurText(newValue ? newValue : "")
                }
              }
            } else {
              onChange(curText)
            }
          }}
          onKeyDown={(event, queryFn) => {
            if (event.keyCode === 13) {
              onChange(curText)
            } else {
              // queryFn()
            }
          }}
          showIcon={true}
          icon={
            <div className="icon prepend-icon">
              {React.createElement(ReactIcon[icon || "MdSearch"])}
            </div>
          }
          downShiftProps={{
            onSelect: v => {
              if (getIsStrict()) {
                fieldInstance.setValue(v)
                if (onChange) onChange(v)
              } else {
                setCurText(v.value)
                onChange(v.value)
              }
            },
          }}
        />
        {getClearButton()}
      </div>

      {error ? <ErrorMessage errorMessage={error} /> : ""}
    </SearchWrapper>
  )
}

Autocomplete.defaultProps = {
  required: false,
  clearOnSelect: false,
  allowMultiple: false,
  showClearButton: false,
}

Autocomplete.propTypes = {
  index: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  dataField: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  icon: PropTypes.string,
  required: PropTypes.bool,
  clearOnSelect: PropTypes.bool,
  showClearButton: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default Autocomplete
